import { defineNuxtRouteMiddleware, navigateTo } from '#app';

export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useSanctumAuth();
  const user = useSanctumUser<{
    hasVerifiedEmail: boolean;
  }>();

  if (isAuthenticated.value && user.value?.hasVerifiedEmail) {
    if (to.name === 'email-verification-required') {
      return navigateTo('/', { replace: true });
    }
    return;
  }

  if (to.name !== 'email-verification-required') {
    return navigateTo('/email-verification-required', { replace: true });
  }
});
